import React from 'react'
import { Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'
import { CloudflareContactForm } from "../components/molecules/CloudflareContactForm"

const pageMeta = {
  title: 'Contact',
  type: 'contact',
  description: 'Contact Lou Groshek',
  keywords: `lou, groshek, lou groshek, contact`,
}

const CloudContactPage = ({ location }) => {

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          subtitle
          copyrightDate
          authorLink
          contactLocation
          description
          authorEmail
          firstName
          menu {
            path
            title
          }
        }
      }
    }`)

  return (
    <Layout location={pageMeta.location} pageType={pageMeta.type}>
      <Row className="heading">
        <Col xs={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }}>
          <h1>{pageMeta.title}</h1>
        </Col>
      </Row>
      <div className="row">
        <div className="col-form col-10 offset-1 col-lg-6 offset-lg-3">
          <p>
            Use this form to contact Lou for information about sessions or
            instruction.{' '}
          </p>
          <CloudflareContactForm data={site} />
        </div>
      </div>
    </Layout>
  )
}

export default CloudContactPage

export const Head = () => <SEO meta={{ ...pageMeta }} />
